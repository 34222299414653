//modules
@import 'modules/all';

h1, h2, h3 {
	margin-top: 10px;
}

h4, h5, h6 {
	margin-top: 0px;
}

hr {
	border-color: #dddddd;
}

.semi-bold {
	font-weight: 600;
}

.bold {
	font-weight: bold;
}

.italic {
	font-style: italic;
}

.footer {
	background-color: #222222;
	color: #FFFFFF;
	margin-top: 20px;
	margin-bottom: -20px;
	padding: 10px;
	font-weight: bold;
	text-align: center;
	vertical-align: middle;
}

.page-header {
	border-bottom: 2px solid #222222;
	padding-bottom: 6px;
	margin-top: 0px;
	margin-bottom: 20px;
}

.red-asterisk {
	font-size: 9px;
	top: -6px;
	color: #A94442;
}

.help-block {
	margin-bottom: 5px;
}

.field-help {
	display: block;
	margin-top: 5px;
	color: #31708F;
}

select option {
	color: #555555;
}

select.empty {
	color: #888888;
}

.email-template {
	font-family: monospace;
	resize: none;
}

.sms-template {
	font-family: monospace;
	resize: none;
}

@media (min-width: 768px) {
	.email-template {
		width: 700px;
	}

	.sms-template {
		width: 550px;
	}
}

.label-black {
	background-color: #222222;
}

.label-grey {
	background-color: #AAAAAA;
}

.action-buttons {
	display: block;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.btn-spacer-xs {
	display: inline-block;
	width: 24px;
}

.btn-black {
	color: #ffffff;
	background-color: #222222;
	border-color: #000000;
}

.btn-black:hover,
.btn-black:focus,
.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
	color: #ffffff;
	background-color: #111;
	border-color: #000000;
}

.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
	background-image: none;
}

.btn-black.disabled,
.btn-black[disabled],
fieldset[disabled] .btn-black,
.btn-black.disabled:hover,
.btn-black[disabled]:hover,
fieldset[disabled] .btn-black:hover,
.btn-black.disabled:focus,
.btn-black[disabled]:focus,
fieldset[disabled] .btn-black:focus,
.btn-black.disabled:active,
.btn-black[disabled]:active,
fieldset[disabled] .btn-black:active,
.btn-black.disabled.active,
.btn-black[disabled].active,
fieldset[disabled] .btn-black.active {
	background-color: #222222;
	border-color: #000000;
}

.btn-black .badge {
	color: #222222;
	background-color: #ffffff;
}

.navbar-nav > li {
	font-weight: 600;
}

/* Taken from SB Admin 2 theme */
.panel-green {
	border-color: #5cb85c;
}

.panel-green .panel-heading {
	border-color: #5cb85c;
	color: #fff;
	background-color: #5cb85c;
}

.panel-green a {
	color: #5cb85c;
}

.panel-green a:hover {
	color: #3d8b3d;
}

.panel-red {
	border-color: #d9534f;
}

.panel-red .panel-heading {
	border-color: #d9534f;
	color: #fff;
	background-color: #d9534f;
}

.panel-red a {
	color: #d9534f;
}

.panel-red a:hover {
	color: #b52b27;
}

.panel-yellow {
	border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
	border-color: #f0ad4e;
	color: #fff;
	background-color: #f0ad4e;
}

.panel-yellow a {
	color: #f0ad4e;
}

.panel-yellow a:hover {
	color: #df8a13;
}
