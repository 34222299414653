//Media queries

// @media(max-width:767px){}
// @media(min-width:768px){}
// @media(min-width:992px){}
// @media(min-width:1200px){}

@mixin mobile() {
    @media only screen and (max-width: 767px) { @content; }
}
@mixin desktop() {
    @media only screen and (min-width: 768px) { @content; }
}
@mixin desktop-lg() {
    @media only screen and (min-width: 1200px) { @content; }
}
@mixin desktop-xlg() {
    @media only screen and (min-width: 1600px) { @content; }
}

//Transition map-pin
@-webkit-keyframes slide-pin
{
    0%   {transform: translatey(0);}
    50% {transform: translatey(1.3rem);}
}

@-moz-keyframes slide-pin
{
    0%   {transform: translatey(0);}
    50% {transform: translatey(1.3rem);}
}

@keyframes slide-pin
{
    0%   {transform: translatey(0);}
    50% {transform: translatey(1.3rem);}
}

//icon animation burst
@keyframes burst {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
